import "froala-editor/js/plugins/align.min.js"
import "froala-editor/js/plugins/code_view.min.js"
import "froala-editor/js/plugins/code_beautifier.min.js"
import "froala-editor/js/plugins/colors.min.js"
import "froala-editor/js/plugins/draggable.min.js"
import "froala-editor/js/plugins/emoticons.min.js"
import "froala-editor/js/plugins/entities.min.js"
import "froala-editor/js/plugins/file.min.js"
import "froala-editor/js/plugins/font_family.min.js"
import "froala-editor/js/plugins/font_size.min.js"
import "froala-editor/js/plugins/inline_style.min.js"
import "froala-editor/js/plugins/image.min.js"
import "froala-editor/js/plugins/line_breaker.min.js"
import "froala-editor/js/plugins/line_height.min.js"
import "froala-editor/js/plugins/link.min.js"
import "froala-editor/js/plugins/lists.min.js"
import "froala-editor/js/plugins/markdown.min.js"
import "froala-editor/js/plugins/paragraph_format.min.js"
import "froala-editor/js/plugins/paragraph_style.min.js"
import "froala-editor/js/plugins/quick_insert.min.js"
import "froala-editor/js/plugins/save.min.js"
import "froala-editor/js/plugins/table.min.js"
import "froala-editor/js/plugins/track_changes.min.js"
import "froala-editor/js/plugins/url.min.js"
import "froala-editor/js/plugins/video.min.js"
import "froala-editor/js/plugins/fullscreen.min.js"
import "froala-editor/js/plugins/word_paste.min"

import "froala-editor/js/third_party/embedly.min.js"

import FroalaEditor from "froala-editor";
import { FancyMessage } from "js/utils/fancy_message.es6";

FroalaEditor.DefineIconTemplate('bootstrap_icons', '<i class="bi bi-[NAME]" aria-hidden="true"></i>');

FroalaEditor.DefineIcon('AI_dropdown', { NAME: 'cpu', template: 'bootstrap_icons' });

FroalaEditor.RegisterCommand('AI_run', {
  title: 'AI',
  focus: false,
  undo: true,
  refreshAfterCallback: false,
  callback: async function callback() {
    if(!this) {
      return;
    }

    const froala = this;

    if (froala.selection.text().length === 0) {
      new FancyMessage('error', "Please select a text to be send as prompt to AI assistant.").display();
      return;
    }

    const richController = this.$oel[0].dataset.controller;
    const urlString = this.$oel[0].getAttribute(`data-${richController}-ai-prompt-url-value`)
    let url = new URL(urlString);

    const prompt = this.selection.text();
    url.searchParams.set('prompt[prompt]', prompt);

    froala.html.insert(FroalaEditor.START_MARKER + 'Processing...' + FroalaEditor.END_MARKER);

    fetch(url, { method: 'POST' })
      .then((response) => {
        if(response.ok){
          return response.json();
        }
        if (response.status === 401) {
          return Promise.reject("Access Denied");
        }

        return Promise.reject("Invalid Request");
      })
      .then((json) => {
        if (!json.success) {
          return Promise.reject(json.errors);
        }

        froala.html.insert(json.response);
      })
      .catch((error) => {
        froala.commands.undo();
        new FancyMessage('error', error).display();
      });
  }
});

FroalaEditor.RegisterCommand('AI_intro_template', {
  title: 'AI Intro Template',
  focus: false,
  undo: true,
  refreshAfterCallback: false,
  callback: async function callback() {
    if (!this) {
      return;
    }

    const template = this.$oel[0].dataset.richEditorAiWriteIntroPromptValue;
    this.html.insert(FroalaEditor.START_MARKER + template + FroalaEditor.END_MARKER);
  }
});

FroalaEditor.RegisterCommand('AI_outline_template', {
  title: 'AI Outline Template',
  focus: false,
  undo: true,
  refreshAfterCallback: false,
  callback: async function callback() {
    if (!this) {
      return;
    }

    const template = this.$oel[0].dataset.richEditorAiWriteOutlinePromptValue;
    this.html.insert(FroalaEditor.START_MARKER + template + FroalaEditor.END_MARKER);
  }
});

FroalaEditor.RegisterCommand('AI_dropdown', {
  title: 'AI',
  type: 'dropdown',
  focus: false,
  undo: true,
  refreshAfterCallback: true,
  options: {
    'AI_run': 'Run',
    'AI_intro_template': 'Write an intro',
    'AI_outline_template': 'Write an outline',
  },

  callback: function (cmd, val) {
    this.commands.exec(val);
  },
});

FroalaEditor.RegisterShortcut(FroalaEditor.KEYCODE.SEMICOLON, "AI_dropdown", null, ";", false, false);